// ARAJ IP
// export const BASEURL = "http://172.16.1.85:9090/v1/";
//
// PARTY IP ADDRESS
//export const BASEURL = 'http://172.16.1.58:9090/v1/'; //party
//
// KASHIF IP ADDRESS
// export const BASEURL = 'http://172.16.1.157:9090/v1/'; //kashif
// export const BASEURL = 'http://172.16.2.71:9090/v1/'; //arham
export const CUSTOMURL1 = "https://scdev.ormelive.com/v1/"; // arham
// export const CUSTOMURL1 = "http://172.16.2.71:5000/v1/"; // arham
// export const CUSTOMURL1 = "http://172.16.1.58:5000/v1/"; // party

/// LIVE URL
// export const BASEURL = "https://apiv2.konnect.bio/v1/"; //live url
export const BASEURL = "https://backend.ormelive.com/v1/"; //live urls dev .
//export const BASEURL = "https://backendlive.ormelive.com/v1/"; ////live url live.

// export const BASEURL = 'https://kbdev-v2.herokuapp.com/v1/'; 
//export const BASEURL = 'http://172.16.1.157:9090/v1/'; //Chota Kashif
// export const BASEURL = 'https://apiv2.kblive.com/v1/'; sads build 6
export const visitorURL = "https://dev.ormelive.com";
export const TEMPURL = "https://kbsly.com/v1/";
export const CUSTOMURL = "https://apiv2-dev.ormelive.com/v1/";


